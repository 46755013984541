import React from "react";
import ReactDOM from "react-dom";
import { setConfig } from "@truedat/core/truedatConfig";
import { config } from "truedatConfig";
import App from "./App";
import "styles/theme/semantic.less";
import "styles/core/components.less";
import "styles/ai/components.less";
import "styles/dd/components.less";
import "styles/bg/components.less";
import "styles/df/components.less";
import "styles/dq/components.less";
import "styles/lm/components.less";
import "styles/se/components.less";
import "styles/text.less";
import "styles/dq.less";
import "styles/dl.less";

import("lodash/fp").then(_ => {
  // eslint-disable-next-line fp/no-mutation
  window._ = _;
});

setConfig(config);

ReactDOM.render(<App />, document.getElementById("app"));
