import _ from "lodash/fp";
import DateTime from "@truedat/core/components/DateTime";
import { defaultImplementationColumns } from "@truedat/dq/src/selectors/getRuleImplementationColumns";

const defaultImplementationColumnsRule = _.filter(
  ({ name }) => !_.includes(name)(["inserted_at", "updated_at"])
)(defaultImplementationColumns);

const customImplementationColumns = [
  {
    name: "inserted_at",
    fieldSelector: ({ inserted_at }) => ({ value: inserted_at }),
    fieldDecorator: DateTime,
    sort: { name: "inserted_at" }
  },
  {
    name: "updated_at",
    fieldSelector: ({ updated_at }) => ({ value: updated_at }),
    fieldDecorator: DateTime,
    sort: { name: "updated_at" }
  }
];

export const ruleImplementationColumns = () => {
  return _.concat(
    defaultImplementationColumnsRule,
    customImplementationColumns
  );
};
