import React from "react";

export const groups = [
  [
    "Más comunes",
    ["taxonomy", "class.raw", "linked_concepts", "with_content.raw"]
  ],
  ["_hidden", ["note_status"]]
];

export const structureFiltersGroup = (state = groups) => state;
